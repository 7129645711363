













































import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { EditConsumptionDataExchangeConfigurationInput, TreeNodeType } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { isString } from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import editMutation from './edit.gql';
import removeMutation from './remove.gql';
import query from './view.gql';
import {
  AppCustomerConsumptionDataExchangeConfigurationSettingsViewEditMutation,
  AppCustomerConsumptionDataExchangeConfigurationSettingsViewEditMutationVariables,
} from './__generated__/AppCustomerConsumptionDataExchangeConfigurationSettingsViewEditMutation';
import {
  AppCustomerConsumptionDataExchangeConfigurationSettingsViewRemoveMutation,
  AppCustomerConsumptionDataExchangeConfigurationSettingsViewRemoveMutationVariables,
} from './__generated__/AppCustomerConsumptionDataExchangeConfigurationSettingsViewRemoveMutation';
import { ApolloQueryResult } from 'apollo-client/core/types';
import {
  AppCustomerConsumptionExchangeConfigurationSettingsViewQuery,
  AppCustomerConsumptionExchangeConfigurationSettingsViewQueryVariables,
  AppCustomerConsumptionExchangeConfigurationSettingsViewQuery_customers_first_consumptionDataExchangeConfigurations_first_treeNodes_items,
} from './__generated__/AppCustomerConsumptionExchangeConfigurationSettingsViewQuery';

type FormData = Pick<EditConsumptionDataExchangeConfigurationInput, 'consent' | 'label' | 'treeNodeIds'>;

@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'cache-and-network',
      variables(this: SettingsView): AppCustomerConsumptionExchangeConfigurationSettingsViewQueryVariables {
        return {
          customerId: this.customerId,
          vendorApiId: this.vendorApiId,
        };
      },
      result(
        this: SettingsView,
        { data }: ApolloQueryResult<AppCustomerConsumptionExchangeConfigurationSettingsViewQuery>,
      ): void {
        if (data != undefined) {
          this.consumptionDataExchangeType =
            data.customers.first.consumptionDataExchangeConfigurations.first.consumptionDataExchangeType;
          this.treeNodes = data.customers.first.consumptionDataExchangeConfigurations.first.treeNodes.items;
        }
      },
    },
  },
  data() {
    return { customers: undefined, consumptionDataExchangeType: undefined, treeNodes: undefined };
  },
})
export default class SettingsView extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  @StringProp(true)
  private readonly vendorApiId!: string;
  private implicitBoolean = true;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private readonly customers?: AppCustomerConsumptionExchangeConfigurationSettingsViewQuery['customers'];

  private consumptionDataExchangeType?: string;

  private treeNodes?: AppCustomerConsumptionExchangeConfigurationSettingsViewQuery_customers_first_consumptionDataExchangeConfigurations_first_treeNodes_items[];

  private pluckIds<T>(objects: { id: T }[] | null): T[] {
    return objects === null ? [] : objects.map(({ id }) => id);
  }

  private get rootIds(): string[] {
    return this.customerId ? [this.customerId] : [];
  }

  private get consentDescription(): string {
    return `Hiermit bestätige ich, dass die Intgeration zwischen der GK4.0 Plattform und ${this.consumptionDataExchangeType} aktiviert werden soll. Es sollen alle Daten anhand der gesetzten Konfiguration übermittelt oder empfangen werden. Bitte speichern Sie die neuen Einstellungen.`;
  }

  private consentStatusText(consent: boolean): string {
    if (consent) {
      return `Die Integration wurde aktiviert. Es werden anhand der vorgenommenen Konfiguration Dateien importiert & Verbrauchsdaten bereitgestellt. Wenn Sie die Integration deaktivieren möchten, dann entfernen Sie den Haken unter Einverständniserklärung und speichern die neuen Einstellungen.`;
    }

    return `Die Integration wurde deaktiviert. Es werden aktuell keine Daten importiert oder Verbrauchsdaten bereitgestellt.`;
  }

  private async edit(input: FormData): Promise<void> {
    const consumptionDataExchangeConfigurationId =
      this.customers?.first.consumptionDataExchangeConfigurations.first?.id;
    if (consumptionDataExchangeConfigurationId === undefined) {
      return;
    }

    const { data } = await this.$apollo.mutate<
      AppCustomerConsumptionDataExchangeConfigurationSettingsViewEditMutation,
      AppCustomerConsumptionDataExchangeConfigurationSettingsViewEditMutationVariables
    >({
      mutation: editMutation,
      variables: {
        input: {
          consumptionDataExchangeConfigurationId,
          ...input,
        },
      },
    });

    if (!data) {
      throw new Error('Die Konfiguration des Verbrauchsdatenaustausch konnte nicht gespeichert werden');
    }

    if (!data.editConsumptionDataExchangeConfiguration.consumptionDataExchangeConfiguration) {
      throw new Error(
        `Die Konfiguration des Verbrauchsdatenaustausch konnte nicht gespeichert werden: ${data.editConsumptionDataExchangeConfiguration.consumptionDataExchangeConfiguration}`,
      );
    }

    const text = `Die Konfiguration des Verbrauchsdatenaustausch wurde gespeichert`;
    this.consumptionDataExchangeType =
      data.editConsumptionDataExchangeConfiguration.consumptionDataExchangeConfiguration.consumptionDataExchangeType;
    this.ADD_TOAST_MESSAGES({ messages: [{ text }] });
  }

  private async remove(): Promise<void> {
    const consumptionDataExchangeConfigurationId =
      this.customers?.first.consumptionDataExchangeConfigurations.first?.id;
    if (consumptionDataExchangeConfigurationId === undefined) {
      return;
    }

    if (!window.confirm('Die Verbrauchsdatenaustausch Konfiguration wurde entfernt')) {
      return;
    }

    const { data } = await this.$apollo.mutate<
      AppCustomerConsumptionDataExchangeConfigurationSettingsViewRemoveMutation,
      AppCustomerConsumptionDataExchangeConfigurationSettingsViewRemoveMutationVariables
    >({
      mutation: removeMutation,
      variables: { input: { consumptionDataExchangeConfigurationId } },
    });

    if (!data) {
      throw new Error('Die Verbrauchsdatenaustausch Konfiguration konnte nicht entfernt werden');
    }

    const text = `Die Verbrauchsdatenaustausch Konfiguration wurde entfernt`;
    this.ADD_TOAST_MESSAGES({ messages: [{ text }] });

    this.$router.push({ name: 'AppCustomer/Customer/VendorApis' });
  }

  private isNonEmptyString(value: unknown): boolean {
    return isString(value) && value.length > 0;
  }

  private get consumptionTreeNodeTypes(): TreeNodeType[] {
    return [TreeNodeType.RootDirectory, TreeNodeType.Directory, TreeNodeType.Property];
  }

  private get consumptionSelectableTreeNodeTypes(): TreeNodeType[] {
    return [TreeNodeType.RootDirectory, TreeNodeType.Directory, TreeNodeType.Property];
  }
}
